import React from "react";
import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import { Helmet } from "react-helmet-async";
import Dino from "./contact-dino.jpg";
import Sobre from "./fundo_sobre.png";
import Local from "./location.webp";

function Contato() {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="styles/contact.css" />
                <link rel="stylesheet" type="text/css" href="styles/contact_responsive.css" />
                {/* <script src="js/contact.js"></script> */}

            </Helmet>
            <Header />



            <div className="home d-flex flex-column align-items-start justify-content-end">
                <div className="parallax_background parallax-window" data-parallax="scroll" data-image-src={Sobre} data-speed="0.8"></div>
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="home_text"><span>Fale</span> Conosco</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact">
                <div className="container">
                    <div className="row">


                        <div className="col-lg-6">
                            <div className="contact_content">
                                <div className="contact_title">Envie sua mensagem</div>
                                <div className="contact_text">
                                    <p>Seja para propor ideias inovadoras, relatar uma experiência positiva ou apontar qualquer aspecto que possamos melhorar, estamos prontos para ouvir e responder a todas sgestões.</p>
                                </div>
                                <div className="contact_form_container">
                                    <form action="contact.php" className="contact_form" method="post" id="contactForm" novalidate="novalidate">
                                        <input type="text" className="contact_input" name="name" id="name" placeholder="Nome" required="required" />
                                        <input type="email" className="contact_input" name="email" id="email" placeholder="Email" required="required" />
                                        <input type="text" className="contact_input" name="subject" id="subject" placeholder="Assunto" />
                                        <textarea className="contact_input contact_textarea" id="message" placeholder="Mensagem" required="required"></textarea>
                                        <button className="contact_button button">Enviar</button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="contact_image">
                                <div className="background_image" ></div>
                                <img src={Dino} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <iframe className="col-12 framemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.0979342593578!2d-44.61499118508423!3d-20.00440398656216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa733e4747e09d7%3A0x4b440341091b47d8!2sMambeba%20Restaurante%20Fazenda!5e0!3m2!1spt-BR!2sbr!4v1690169441461!5m2!1spt-BR!2sbr" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <Footer />
        </>
    )
}
export default Contato;