import React from "react";
function Painel() {
	return (
		<>

			<div className="news">
				<div className="section_title_container">

					{/* <h1 className="section_title text-center">Vem ser Feliz no Mambeba</h1>
					<div className="results_title_container text-center">
						<div className="results_title">Um Domingo Inesquecível</div>
						<div className="results_subtitle">Adquira seu ingresso</div>
					</div> */}
					<section id="grid-parque" className="padding " data-gtm-vis-recent-on-screen-32376511_142="1253"
						data-gtm-vis-first-on-screen-32376511_142="1253" data-gtm-vis-total-visible-time-32376511_142="100"
						data-gtm-vis-has-fired-32376511_142="1">
						<div className="container">
							<div className="row">
								<img src="./images/banner_longo.png"/>
								<div className="column">
									<a href="#"><img className="imagem_painel" loading="lazy" id="img-transform"
										src="./images/Hadikali.png"
										alt="imagem Hadikali" /></a>
									<a href="#"><img className="imagem_painel" loading="lazy" id="img-transform"
										src="./images/hopiniver-2022.png"
										alt="imagem Hopi Niver" /></a>
								</div>
								<div className="column">
									<a href="#"><img className="imagem_painel" loading="lazy" id="img-transform"
										src="./images/vip_pass2021.png"
										alt="imagem Vip Pass" /></a>
									<a href="#"><img className="imagem_painel" loading="lazy" id="img-transform"
										src="./images/Gastronomia.png"
										alt="imagem Gatronomia" /></a>
									<a href="#"><img className="imagem_painel" loading="lazy" id="img-transform" src="./images/Localizacao.png"
										alt="imagem Localização" /></a>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			
		

		</>
	)
}
export default Painel;