import React from "react";

function Banner() {
    return (
        <>
            <div className="home">


                <div className="home_slider_container">
                    <div className="owl-carousel owl-theme home_slider">


                        <div className="home_slide">
                            <div className="background_image fundo_Banner1"></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="home_slide">
                            <div className="background_image fundo_Banner2" ></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="home_content" data-animation-in="zoomInDown"
                                                data-animation-out="animate-out fadeOut">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="home_slide">
                            <div className="background_image fundo_Banner3" ></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="home_content" data-animation-in="zoomInDown"
                                                data-animation-out="animate-out fadeOut">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home_slide">
                            <div className="background_image fundo_Banner4" ></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="home_content" data-animation-in="zoomInDown"
                                                data-animation-out="animate-out fadeOut">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home_slide">
                            <div className="background_image fundo_Banner5" ></div>
                            <div className="home_container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="home_content" data-animation-in="zoomInDown"
                                                data-animation-out="animate-out fadeOut">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="home_slider_nav"><i className="arrow right"></i></div>
                </div>
            </div>
            <div className="breaking_news d-flex flex-row align-items-start justify-content-start">
                <div className="breaking_news_title text-right"><img src="./images/logomam.png" /></div>
                <div className="breaking_news_content">
                    <div className="breaking_news_slider_container">
                        <div className="owl-carousel owl-theme breaking_news_slider">

                            <div className="breaking_news_slide"> Diversão - Divirta-se com os brinquedos e animais do Mambeba
                            </div>

                            <div className="breaking_news_slide"> Aventura - Sinta a emoção de entrar no parque Jurássico</div>

                            <div className="breaking_news_slide"> Comida - Aproveite a comida mineira, Churrasco de chão,
                                Pizza, sobremesa e café da tarde!</div>

                            <div className="breaking_news_slide"> Fantasia - Histórias contadas ao vivo com atores e participação
                                das crianças</div>

                            <div className="breaking_news_slide"> Música - Os Melhores Artistas Locais todo Domingo. </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner;