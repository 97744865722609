import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* PÁGINAS */

import Home from "./view/home";
import Sobre from "./view/sobre";
import Contato from "./view/contato";
import Promocao from "./view/promocao";
import { HelmetProvider } from 'react-helmet-async';



function App() {
  return (
    <HelmetProvider>
      <Switch>
        <Router>
          <Route exact path='/' component={Home} />
          <Route exact path='/sobre' component={Sobre} />
          <Route exact path='/contato' component={Contato} />
          <Route exact path='/promocao' component={Promocao} />
          {/* 
          
          <Route exact path='/atendimento' component={Atendimento} /> */}
        </Router>
      </Switch>
    </HelmetProvider>

  );
}

export default App;

