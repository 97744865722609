import React from "react";
import Marcos from './main_large.jpg';


function Faq() {
	return (
		<>

			<div className="elements">
				<div className="container">
				<div className="elements_title text-center pt-5"><h2 className="text-white">PERGUNTAS FREQUENTES</h2></div>
					<div className="row">
						
						<div className="col">
							<div className="acc_tabs">

								<div className="row acc_tabs_row">

									
									<div className="col-lg-6">
										
										<div className="accordions">

											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>O que é o Mambeba?</div></div>
												<div className="accordion_panel">
													<div>
														<p>Somos um lugar mágico e único, um restaurante situado em uma floresta encantada, onde a boa gastronomia se une ao entretenimento para proporcionar momentos aconchegantes e divertidos para toda a família.

															Explore nosso Vale Jurássico, uma experiência simplesmente fascinante! A imersão em um universo de enormes criaturas extintas há milhares de anos certamente oferecerá uma emocionante e educativa jornada para os visitantes. As crianças terão a oportunidade de se conectar com o passado e apreciar a natureza em sua forma mais impressionante.

															Com a combinação única de ambiente encantador, culinária excepcional e entretenimento envolvente, o Mambeba Adventure é um destino imperdível para momentos especiais e memoráveis com a família e amigos. Prepare-se para uma jornada fascinante que certamente deixará lembranças inesquecíveis em todos os que nos visitarem!</p>
													</div>
												</div>
											</div>
											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>Como funciona o Day Use no Mambeba Adventure?</div></div>
												<div className="accordion_panel">
													<div>
														<p>O Day Use no Mambeba Adventure é uma experiência incrível para toda a família! Confira nossos valores por faixa etária:<br/>

															👶 Crianças de 0 a 2 anos: Gratuito<br/>
															👧 Crianças de 3 a 6 anos: R$ 109,00<br/>
															🧒 Crianças de 7 a 11 anos: R$ 119,00<br/>
															🧑 Adultos: R$ 159,00<br/>

															Com esse valor único, você terá acesso a todas as comidas e sobremesas disponíveis no cardápio, música ao vivo para animar o dia, além de poder aproveitar nossa piscina (para crianças até 11 anos) e participar de todas as atividades emocionantes oferecidas, incluindo a imperdível experiência no Vale Jurássico.

															Venha desfrutar de um dia repleto de diversão, aventuras e delícias gastronômicas em meio à magia da nossa floresta encantada. Estamos ansiosos para recebê-los no Mambeba Adventure! 🌳🦕🦖</p>
													</div>
												</div>
											</div>

											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>O que está incluso no valor do ingresso?</div></div>
												<div className="accordion_panel">
													<div>
														<p>O valor pago inclui um extenso buffet de comida mineira e tradicional, sobremesas, pizzas, buffet de massas ao vivo e risoto, churrasco, café ao fim da tarde com biscoito frito e bolinho de chuva. Também está incluída música ao vivo, teatrinhos, oficina de cupcake, alimentação dos animais, guerra de balão d’água, acesso à piscina para crianças até 11 anos, passeio de barco pirata, acesso ao Vale Jurássico e muito mais.

															No entanto, é importante notar que as bebidas não estão incluídas no valor pago e devem ser pagas separadamente. Portanto, as bebidas serão cobradas à parte durante o evento.</p>
													</div>
												</div>
											</div>


											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>Posso levar meu bichinho de estimação?</div></div>
												<div className="accordion_panel">
													<div>
														<p>No Mambeba, é permitida a entrada de animais domésticos de pequeno porte, desde que sejam mantidos todo o tempo de coleira e guia, com a supervisão de um adulto responsável. Essa medida visa garantir a segurança e o bem-estar de todos os visitantes, permitindo que todos desfrutem do espaço de forma tranquila e harmoniosa. Portanto, fique à vontade para trazer seu animal de estimação, desde que siga essas orientações. Agradecemos a sua compreensão e colaboração!</p>
													</div>
												</div>
											</div>


											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>Posso levar um cooler com bebidas de casa?</div></div>
												<div className="accordion_panel">
													<div>
														<p>Não é permitida a entrada de nenhum tipo de bebida, incluindo bebidas trazidas de casa. Temos um cardápio repleto de opções a preços de mercado para atender às suas necessidades durante a visita. Agradecemos a sua compreensão e esperamos que aproveite o nosso menu disponível no local.</p>
													</div>
												</div>
											</div>

											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>O Mambeba é um hotel? Tem lugar para dormir?</div></div>
												<div className="accordion_panel">
													<div>
														<p>O Mambeba Adventure é um restaurante localizado em uma floresta encantada. Trabalhamos apenas aos domingos (e alguns feriados) com o sistema de Day Use, portanto não oferecemos nenhum tipo de hospedagem.</p>
													</div>
												</div>
											</div>
											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center"><div>Tem promoção para aniversariante?</div></div>
												<div className="accordion_panel">
													<div>
														<p>Sim, no Mambeba, há um mimo especial para aniversariantes! Os aniversariantes da semana ganham um bolo de tamanho P como cortesia. Para ganhar, basta informar o nome e a data do aniversário no momento da reserva. É uma maneira carinhosa e especial de celebrar o aniversário no estabelecimento, tornando o dia ainda mais memorável e doce para o aniversariante.</p>
													</div>
												</div>
											</div>
											<div className="accordion_container">
												<div className="accordion d-flex flex-row align-items-center active"><div>Posso ir ao Mambeba sem reserva?</div></div>
												<div className="accordion_panel">
													<div>
														<p>No Mambeba, trabalhamos exclusivamente com reservas feitas através do nosso sistema de reservas. Para garantir sua visita e aproveitar todos os encantos do local, recomendamos fazer a reserva com antecedência. Dessa forma, poderemos proporcionar uma experiência mágica e memorável para você e sua família.</p>
													</div>
												</div>
											</div>

										</div>
									</div>


									<div className="col-lg-6 tabs_col">
										<div className="">
											<div className="tabs_container">
												<img src={Marcos} />

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



		</>

	)
}
export default Faq;