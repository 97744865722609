import React from "react";


function Insta(){
    return(
        <>
        	<div className="news">
				<div className="col-12">
					<div className="row">
						<div className="col">
							<div className="section_title_container">
								<div className="section_title text-center">
									<h1>Mambeba no Instagram</h1>
								</div>
								<div className="section_subtitle text-center">@mambeba.restaurante</div>
							</div>
						</div>
					</div>
					<div className="row news_row">


						<div className="col-lg-3">
							<div className="news_post">
								<div id="local1" className="news_post_image">

									<div className="news_post_date">
										<a href="#local1">
											<div className="d-flex flex-column align-items-center justify-content-center">
												
											</div>
										</a>
									</div>
								</div>
								
							</div>
						</div>


						<div className="col-lg-3">
							<div className="news_post">
								<div id="local2" className="news_post_image">

									<div className="news_post_date">
										<a href="#local1">
											<div className="d-flex flex-column align-items-center justify-content-center">
												
											</div>
										</a>
									</div>
								</div>
								
							</div>
						</div>


						<div className="col-lg-3">
							<div className="news_post">
								<div id="local3" className="news_post_image">

									<div className="news_post_date">
										<a href="#local1">
											<div className="d-flex flex-column align-items-center justify-content-center">
												
											</div>
										</a>
									</div>
								</div>
								
							</div>
						</div>

						<div className="col-lg-3">
							<div className="news_post">
								<div id="local4" className="news_post_image">

									<div className="news_post_date">
										<a href="#local1">
											<div className="d-flex flex-column align-items-center justify-content-center">
												
											</div>
										</a>
									</div>
								</div>
								
							</div>
						</div>

					</div>
				</div>
			</div>
        </>
    )
}
export default Insta;