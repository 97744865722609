import React from "react";

function Calendario() {
	return (
		<>
			<section className="ftco-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="section_title col-md-6 text-center mb-5">
							<h1 className="heading-section">Adquira seu Ingresso</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="elegant-calencar d-md-flex">
								<div className="wrap-header d-flex align-items-center img img-dino"
								>
									<span className="head-day-text" id="reset">&#x1F4C5;Escolha uma data</span>
									<div id="header" className="p-0">

										<div className="">

											<div className="head-day"></div> <span className="head-day-simbol pt-5">&#8226;</span><span className=" head-day-text pt-5">Selecione a data de visita </span><br/>
											
										</div>

									</div>
								</div>
								<div className="calendar-wrap">
									<div className=" headercal w-100 button-wrap">
										<div className="pre-button d-flex align-items-center justify-content-center"><i 

											className="fa fa-chevron-circle-left circulo-dir"></i></div>
										<div className="titulomes">
											<div className="head-month"></div>
										</div>
										<div className="next-button d-flex align-items-center justify-content-center"><i

											className="fa fa-chevron-circle-right circulo-dir"></i></div>
									</div>
									<table id="calendar">
										<thead>
											<tr>
												<th>Dom</th>
												<th>Seg</th>
												<th>Ter</th>
												<th>Qua</th>
												<th>Qui</th>
												<th>Sex</th>
												<th>Sab</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td id="domingo" className="domin"></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</>
	)
}
export default Calendario;