import React from "react";
import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import Fundo2 from "../sobre/fundo_sobre2.png";
import Fundo1 from "../sobre/fundo_sobre.png";
import Helmet from 'react-helmet';
import Camila from "./camila.jpg";
import Ju from "./ju.jpg";
import Outra from "./dani.jpg";

// import "./about_responsive.css";
// import "./about.css";



function Sobre() {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="styles/about.css" />
                <link rel="stylesheet" type="text/css" href="styles/about_responsive.css" />
                <script src="js/about.js"></script>
            </Helmet>
            <Header />
            <div class="home2 d-flex flex-column align-items-start justify-content-end">
                <div class="parallax_background parallax-window" data-parallax="scroll" data-image-src={Fundo1} data-speed="0.8"></div>
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="home_text2">Bem-vindo  <span>ao Mambeba</span></div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="about">
                <div class="container">
                    <div class="row">


                        <div class="col-xl-6 order-xl-1 order-2">
                            <div class="about_image">
                                <div class="background_image" ></div>
                                <img src={Fundo2} alt="" />
                            </div>
                        </div>


                        <div class="col-xl-6 order-xl-2 order-1">
                            <div class="about_content">
                                <div class="about_content_container">
                                    <div class="section_title_container">
                                        <div class="section_title"><h1>O Refúgio Mágico de Itaúna!</h1></div>
                                    </div>
                                    <div class="about_title">Venha conhecer</div>
                                    {/* <div class="about_subtitle">Adquira seu ingresso</div> */}
                                    <div class="about_text">
                                        <p>

                                            Em Itaúna, Minas Gerais, encontra-se o Restaurante Mambeba, um verdadeiro oásis gastronômico e de entretenimento, onde os sabores da comida mineira se fundem com a magia do Parque Jurássico, histórias infantis com teatro ao vivo, música envolvente e a beleza exuberante da natureza ao nosso redor.

                                            Nossa história é tecida com amor, criatividade e paixão. Há anos, a família Faria Adelário sonhou em criar um lugar onde todas as gerações pudessem se reunir para desfrutar de momentos memoráveis e experiências únicas. Assim, surgiu o Restaurante Mambeba, com o objetivo de encantar os paladares, cativar as crianças e envolver a todos em uma atmosfera mágica e acolhedora.

                                            Com o tema Parque Jurássico, proporcionamos uma imersão fascinante na era dos dinossauros. Nossos visitantes têm a oportunidade de vivenciar réplicas realistas desses majestosos seres pré-históricos, caminhando em meio a trilhas cercadas por uma vegetação exuberante, que nos transporta para tempos remotos. Essa experiência é especialmente querida pelos pequenos, que se encantam com a sensação de estar explorando um mundo perdido.

                                            A comida mineira é o ponto alto da nossa gastronomia, cada mordida é uma celebração dos sabores autênticos de Minas Gerais.

                                            E para as crianças, oferecemos momentos mágicos com teatro ao vivo, onde personagens encantados ganham vida e contam histórias emocionantes e divertidas. Esses espetáculos cativantes transportam a todos para universos fantásticos e enchem o restaurante de risos e alegria.

                                            Somos gratos por proporcionar experiências que ficarão gravadas na memória de todos que nos visitam.

                                            Seja para uma refeição saborosa, momentos de diversão e teatro para as crianças, música ao vivo ou simplesmente para apreciar a natureza, convidamos você a embarcar em uma jornada especial no Restaurante Mambeba. Estamos ansiosos para receber você e sua família com todo o carinho e encanto que nosso espaço pode oferecer. Até breve!</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="testimonials">
                <div class="parallax_background" data-image-src="images/testimonials.jpg"></div>
                <div class="test_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="section_title_container text-center">
                                <div class="section_title"><h1>Comentários</h1></div>
                                <div class="section_subtitle">de quem já foi</div>
                            </div>
                            <div class="testimonials_slider_container">
                                <div class="owl-carousel owl-theme test_slider">


                                    <div>
                                        <div class="test_item text-center">
                                            <div class="test_text">
                                                <p>Lugar excelente, agradável, aconchegante e lindo!
                                                    Muito bom para levar crianças, pois oferecem brincadeiras, além de casinha na  árvores, cenário para histórias, histórias ao vivo  com personagens, a casa dos três porquinhos, etc ..  Tudo lindo !
                                                    Comida maravilhosa e sobremesas de dar água na boca, vale a pena conhecer !!!</p>
                                            </div>
                                            <div class="test_image"><img src={Outra} alt="" /></div>
                                            <div class="test_name"><a href="#">Leticia</a><span></span></div>
                                        </div>
                                    </div>


                                    <div>
                                        <div class="test_item text-center">
                                            <div class="test_text">
                                                <p>Fiquei super satisfeita, só pq não deu tempo de conhecer todos os detalhes,as horas voaram,não cheguei  nem colocar o churrasco,estava tudo delicioso , apaixonada com o tropeiro e com o torresmo e mandioca tudo com sabor de roça muito,desejei comer mais frutas só que já havia acabado mas já eram quase 15 hs ,hora que já está quase encerrando ,estava tudo muito delicioso ,minha filha ficou apaixonada com tudo ,amou a piscina também,a casinha dos porquinhos ,e o trem pra ver os dinossauros..</p>
                                            </div>
                                            <div class="test_image"><img src={Ju} alt="" /></div>
                                            <div class="test_name"><a href="#">Tatiane Teixeira</a><span></span></div>
                                        </div>
                                    </div>


                                    <div>
                                        <div class="test_item text-center">
                                            <div class="test_text">
                                                <p>Comida preparada com muito cuidado e carinho! Doces e comida deliciosa!

                                                    Adequado para crianças: Tem muita animação para a criançada! Mini teatro, oficina de biscoitos, piscina, barco pirata, floresta com dinossauros, natureza, animais e um excelente atendimento!</p>
                                            </div>
                                            <div class="test_image"><img src={Camila} alt="" /></div>
                                            <div class="test_name"><a href="#">Kellen Cristiane</a><span></span></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Sobre;